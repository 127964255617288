import React from "react"
import { graphql } from "gatsby"
import Meta from "../../components/layouts/meta"
import BreadCrumb from "../../components/layouts/bread-crumb"
import Layout from "../../components/layouts/default"
import * as styles from "../../styles/page/second.module.scss"

// プロジェクト
import TopFeature from "../../components/parts/top/feature"

const ArticlePage = ({ data }) => {
  const featureData = data.feature.edges

  const title = '特集記事'
  
  return (
    <Layout>
      <Meta 
        title={title}
        path="/article/"
      />

      <BreadCrumb
        currentTitle={title}
      />

      <div className={styles.pageTitle}>
        <h1 className={styles.pageTitleHeading}>{title}</h1>
      </div>

      <main className={styles.pageContents}>
        
        <div className={styles.projectList}>
          <div className={styles.projectListInner}>
            {featureData.map( (item, id) => {
              return <TopFeature key={`top-feature-${id}`} index={id} data={item.node.frontmatter} />
            })}
          </div>
        </div>

      </main>
    </Layout>
  )
}
export default ArticlePage

export const query = graphql`
  query FeatureListQuery {
    feature: allMarkdownRemark(
      filter: {fields: {slug: {regex: "/article/"}}}
      sort: { fields: [frontmatter___order], order: ASC }
      limit: 100
    ) {
      edges {
        node {
          frontmatter {
            title
            order
            mainImage
            slug
          }
        }
      }
      totalCount
    }
  }
`